import React from "react";
import { Accordion, Card, Badge } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import IcoFont from "react-icofont";

class OfferCards extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false, // State to toggle accordion
		};
	}

	toggleAccordion = () => {
		this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
	};

	renderSingleOffer(offer) {
		return (
			<>
				{offer && (
					<Card className="mb-3 shadow-sm border-0">
						<Card.Body
							style={{
								backgroundColor: "#f9f9f9",
								borderRadius: "8px",
								paddingBottom: 5,
								paddingTop: 5,
							}}
						>
							<span>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										marginBottom: "10px",
									}}
								>
									<div
										style={{
											background: "grey",
											color: "white",
											fontWeight: "bold",
											fontSize: "0.7rem",
											padding: "0.5px 10px",
											borderRadius: "8px",
											position: "relative",
											boxShadow:
												"0 4px 8px rgba(0, 0, 0, 0.2)",
											clipPath:
												"polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0% 100%)",
										}}
									>
										{`Offer Available`}
										<div
											style={{
												position: "absolute",
												top: "50%",
												right: "-20px",
												transform: "translateY(-50%)",
												width: "20px",
												height: "20px",
												backgroundColor: "#4CAF50",
												clipPath:
													"polygon(50% 0, 100% 50%, 50% 100%, 0 50%)",
												boxShadow:
													"0 4px 6px rgba(0, 0, 0, 0.2)",
											}}
										/>
									</div>
								</div>
							</span>
							<Card.Title
								style={{
									fontWeight: "bold",
									fontSize: "0.9rem",
									marginBottom: 4,
									marginTop: 4,
								}}
							>
								<span role="img" aria-label="img">
									🎁{offer.title}
								</span>
							</Card.Title>
							<Card.Text style={{ marginLeft: 4 }}>
								{offer.message}
							</Card.Text>
						</Card.Body>
					</Card>
				)}
			</>
		);
	}

	renderMultipleOffers(offers) {
		return (
			<Accordion defaultActiveKey="0">
				<Card className="shadow-sm border-0">
					<Accordion.Toggle
						as={Card.Header}
						eventKey="0"
						className="d-flex justify-content-between align-items-center"
						style={{
							cursor: "pointer",
							backgroundColor: "#f9f9f9",
							color: "#000",
							fontWeight: "bold",
							borderRadius: "8px",
							padding: "10px 15px",
							alignItems: "center",
						}}
						onClick={this.toggleAccordion}
					>
						<span>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									// marginBottom: "10px",
								}}
							>
								<div
									style={{
										background: "grey",
										color: "white",
										fontWeight: "bold",
										fontSize: "0.7rem",
										padding: "0.5px 10px",
										borderRadius: "8px",
										position: "relative",
										boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
										clipPath:
											"polygon(0 0, 95% 0, 100% 50%, 95% 100%, 0% 100%)",
									}}
								>
									{`${offers.length} Offers Available`}
									<div
										style={{
											position: "absolute",
											top: "50%",
											right: "-20px",
											transform: "translateY(-50%)",
											width: "20px",
											height: "20px",
											backgroundColor: "#4CAF50",
											clipPath:
												"polygon(50% 0, 100% 50%, 50% 100%, 0 50%)",
											boxShadow:
												"0 4px 6px rgba(0, 0, 0, 0.2)",
										}}
									/>
								</div>
							</div>
						</span>
						<IcoFont
							icon={`rounded-${this.state.isOpen ? "up" : "down"}`}
							style={{
								fontSize: "1.2rem",
								color: "grey",
								cursor: "pointer",
							}}
							// onClick={(e) => {
							// 	e.stopPropagation(); // Prevent accordion collapse toggle on icon click
							// 	this.toggleAccordion();
							// }}
						/>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body style={{ paddingBottom: 5, paddingTop: 5 }}>
							{offers.map((offer, index) => (
								<>
									{offer && (
										<div
											key={index}
											style={{
												padding: "10px 0",
												borderBottom:
													index < offers.length - 1
														? "1px solid #ddd"
														: "none",
											}}
										>
											<h6
												style={{
													fontWeight: "bold",
													fontSize: "0.9rem",
												}}
											>
												<span role="img" aria-label="img">
													🎁{offer.title}
												</span>
											</h6>
											<p
												style={{
													marginBottom: "0.5rem",
													marginLeft: 4,
												}}
											>
												{offer.message}
											</p>
										</div>
									)}
								</>
							))}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		);
	}

	render() {
		const { offers } = this.props;

		return offers?.length > 0 ? (
			<div className="mb-1" style={{ width: "100%" }}>
				{offers.length === 1
					? this.renderSingleOffer(offers[0])
					: this.renderMultipleOffers(offers)}
			</div>
		) : null;
	}
}

export default OfferCards;
